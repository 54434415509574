import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectIsMobile } from '../selectors/app';
import { useOnClickOutside } from './useOnClickOutside';

export function useOutsideClickControl(shouldAlwaysAddRef) {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useSelector(selectIsMobile);
  const containerRef = useRef();

  // Generally, React Hooks should be written at the top of a function and never wrapped in a
  // conditional. These are special cases because `shouldAlwaysAddRef ` won't change in a
  // given HO instance and `isMobile` won't change in a given app instance,
  // so this shouldn't cause any unwanted behavior.
  // Source: https://reactjs.org/docs/hooks-rules.html
  if (shouldAlwaysAddRef || !isMobile) {
    // biome-ignore lint/correctness/useHookAtTopLevel: shouldAlwaysAddRef and isMobile are expected to be static
    useOnClickOutside(containerRef, () => setIsOpen(false));
  }

  const handleOnClick = (val) => {
    const value = typeof val === 'boolean' ? val : !isOpen;
    setIsOpen(value);
  };

  return { handleOnClick, isOpen, containerRef };
}
