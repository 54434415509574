import { useSelector } from 'react-redux';
import { selectIsDiscord } from '../../selectors/app';
import { selectDeregisterDisplayAds } from '../../selectors/mint';
import DisplayAdSlot from './DisplayAdSlot';

export default function DisplayAd(props) {
  const deregisterDispalyAds = useSelector(selectDeregisterDisplayAds);
  const isDiscord = useSelector(selectIsDiscord);

  return isDiscord ? null : (
    <DisplayAdSlot {...props} deregisterDispalyAds={deregisterDispalyAds} />
  );
}
