import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { mintSingleton } from '../../../client/mint';

function mapStateToProps(state) {
  return {
    isMintInitialized: state.mint.isInitialized,
  };
}

/**
 * Factory that creates a HOC to manage ad slot registration/requests in Mint
 *
 * @param {object} options
 *  @property {boolean} autoPlay - determines whether slots should be requested by the HOC or by an
 *    external process
 *  @property {boolean} refreshOnPageChange - determines whether slots should be refreshed when the
 *    user navigates to a different page
 */
export default function withAdRegistration(options) {
  const { autoPlay, refreshOnPageChange } = options;

  /**
   * HOC that manages ad slot registration/requests in Mint.
   *
   * @param {string} slotName - the name of the slot, as defined by the ad config
   *  (store.config.ads.adConfig)
   * @param {string} id - the unique ID of the HTML element where the ad will render
   * @param {string} [dimensions] - a string representing the specific dimensions required for this
   *  ad slot (e.g., "320x50")
   * @param {string} matchUrl - used to determine page change. Defined in
   *  withCurrentRouteCustomProps.js
   * @param {boolean} deregisterDisplaySlot - deregisters display ads when specific dialogs open.
   *  See store.mintSingleton.instance?.deregisterDisplayAds
   * @param {boolean} isMintInitialized - triggered by WithAds.js after Mint initializes
   *  user explicitly selects consent preferences
   */
  return function withAdRegistrationInner(BaseComponent) {
    class WithAdRegistration extends Component {
      static propTypes = {
        slotName: PropTypes.string,
        slotNames: PropTypes.array,
        id: PropTypes.string.isRequired,
        dimensions: PropTypes.string,
        matchUrl: PropTypes.string,
        deregisterDisplaySlot: PropTypes.bool,
        handleAdDeregistration: PropTypes.func,

        // mapStateToProps
        isMintInitialized: PropTypes.bool,
      };

      constructor(props) {
        super(props);

        this.slotNames = props.slotNames || [props.slotName];
      }

      componentDidMount() {
        if (this.props.isMintInitialized) {
          this.registerAndRequestSlots();
        }
      }

      componentDidUpdate(prevProps) {
        const { matchUrl, isMintInitialized, deregisterDisplaySlot } =
          this.props;

        if (!isMintInitialized) {
          return;
        }

        if (
          !prevProps.isMintInitialized ||
          (refreshOnPageChange && matchUrl !== prevProps.matchUrl) ||
          (!deregisterDisplaySlot && prevProps.deregisterDisplaySlot)
        ) {
          this.registerAndRequestSlots();
          return;
        }

        if (deregisterDisplaySlot && !prevProps.deregisterDisplaySlot) {
          this.deregisterSlot();
        }
      }

      componentWillUnmount() {
        this.deregisterSlot();
      }

      async registerAndRequestSlots() {
        const { id, dimensions } = this.props;

        await Promise.all(
          this.slotNames.map((slotName) =>
            mintSingleton.instance?.registerSlot(id, slotName, dimensions),
          ),
        );

        if (autoPlay) {
          mintSingleton.instance?.requestSlot(id);
        }
      }

      deregisterSlot() {
        mintSingleton.instance?.deregisterSlot(this.props.id);
        this.props?.handleAdDeregistration?.();
      }

      render() {
        return <BaseComponent {...this.props} />;
      }
    }

    return connect(mapStateToProps)(WithAdRegistration);
  };
}
