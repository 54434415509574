import PropTypes from 'prop-types';
import { getCssStyle } from 'src/common/utils/getCssStyle';

/* eslint-disable max-len */
const HeartEmptyIcon = ({
  width = '30px',
  height = '30px',
  viewBox = '0 -1 30 30',
  fill = getCssStyle('--text-color'),
  onClick,
  className,
  dataTestId,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    data-testid={dataTestId}
    onClick={onClick}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 12.0606C24 9.26885 21.7991 7 19.0909 7C17.3809 7 15.8755 7.90248 15 9.26885C14.1245 7.90248 12.6191 7 10.9091 7C8.20091 7 6 9.26885 6 12.0606C6 13.5619 6.63818 14.903 7.63636 15.8308H7.61182L15 23L22.3882 15.8308H22.3636C23.37 14.903 24 13.5619 24 12.0606V12.0606Z"
      stroke={fill}
      strokeWidth="1.5"
      strokeMiterlimit="10"
    />
  </svg>
);

HeartEmptyIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  fill: PropTypes.string,
};

export default HeartEmptyIcon;
