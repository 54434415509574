import PropTypes from 'prop-types';

// Disabling max-len rule in order to retain integrity of svg rules below
/* eslint-disable max-len */
const LegacyCheckmark = ({
  className,
  style,
  viewBox,
  height,
  width,
  fill,
  dataTestId,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    style={style}
    className={className}
    data-testid={dataTestId}
  >
    <g
      id="checkmark"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        transform="translate(-1041.000000, -136.000000)"
        strokeWidth="3"
        stroke={fill}
      >
        <polyline points="1043 141.082248 1046.73366 145 1054 138"></polyline>
      </g>
    </g>
  </svg>
);

LegacyCheckmark.propTypes = {
  dataTestId: PropTypes.string,
  className: PropTypes.string,
  viewBox: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
};

export default LegacyCheckmark;
