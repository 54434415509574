// mobile upsell
export const UPSELL_TITLE = 'upsell.title';
export const MOBILE_UPSELL_HEADER_LINE1 = 'upsell.mobile.header.line1';
export const MOBILE_UPSELL_HEADER_LINE2 = 'upsell.mobile.header.line2';
export const MOBILE_UPSELL_BODY_LINE1 = 'upsell.mobile.body.line1';
export const MOBILE_UPSELL_BODY_LINE2 = 'upsell.mobile.body.line2';
export const MOBILE_UPSELL_BUTTON_TEXT = 'upsell.mobile.button.text';

// first launch upsell dialog
export const UPSELL_DIALOG_CLOSE_LINK_TEXT = 'upsell.dialog.close.link.text';
export const UPSELL_DIALOG_PREMIUM_OFFERING = 'upsell.dialog.premiumOffering';

// right sidebar upsell
export const UPSELL_SIDEBAR_HEADLINE = 'upsell.sidebar.headline';

// evergreen upsell button
export const UPSELL_START_YOUR_FREE_TRIAL = 'upsell.start.your.free.trial';
export const UPSELL_START_FREE_TRIAL_BTN = 'upsell.start.free.trial.btn';

// shared
export const UPSELL_TRY_PREMIUM = 'upsell.try.premium';
export const UPSELL_CANCEL_ANYTIME = 'upsell.cancel.anytime';
export const UPSELL_GO_PREMIUM = 'upsell.go.premium';
export const UPSELL_UPGRADE_NOW = 'upsell.upgrade.now';
export const UPSELL_NOCREDITCARD_REQUIRED = 'upsell.noCreditCardRequired';
export const UPSELL_MORERADIO_LESSADS_GOPREMIUM =
  'upsell.moreRadio.lessAds.GoPremium';
export const UPSELL_COMMERCIAL_FREE_NEWS = 'upsell.commercialFreeNews';
export const UPSELL_COMMERCIAL_FREE_MUSIC = 'upsell.commercialFreeMusic';
export const UPSELL_COMMERCIAL_FREE_SPORTS = 'upsell.commercialFreeSports';
export const UPSELL_COMMERCIAL_FREE_AUDIOBOOKS =
  'upsell.commercialFreeAudiobooks';
export const UPSELL_LESS_ADS = 'upsell.lessAds';
export const RIGHTUPSELL_TITLE_DISCOUNT = 'upsell.rightSidebar.title.discount';
export const ONE_TIME_DEFAULT_COUPON_FOOTNOTE_TEXT =
  'upsell.onetime.default.coupon.footnote.text';

// on air upsell card
export const UPSELL_ONAIR_TITLE = 'upsell.onair.title';
export const UPSELL_ONAIR_ACCESS = 'upsell.onair.access';
export const UPSELL_ONAIR_DEVICES = 'upsell.onair.devices';
export const UPSELL_ONAIR_ANALYTICS = 'upsell.onair.analytics';

// web upsell page specific
export const UPSELL_FREE_TRIAL_HEADER = 'upsell.free.trial.header';
export const UPSELL_FREE_TRIAL_SUBHEADER = 'upsell.free.trial.subheader';
export const UPSELL_REDEEM_WITH_PAYMENT_SUBMIT_BUTTON =
  'upsell.redeem.with.payment.submit.button';
export const UPSELL_NO_THANKS_BUTTON = 'upsell.no.thanks.button';
export const UPSELL_NO_THANKS_FREE_TRIAL = 'upsell.no.thanks.free.trial';
export const UPSELL_PARTNER_AUTH_CUSTOM_HEADER =
  'upsell.partnerAuth.customHeader';
