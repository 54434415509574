/* eslint-disable max-len */
// Experiment keys for A/B Testing the Upsell Paths
// *** FIRST LAUNCH EXPERIMENTS *** //
// experiment to enable dialog
export const FIRSTLAUNCH_UPSELL_DIALOG_ENABLED =
  'web.premium.firstLaunchUpsell.enabled';

// experiment to change the background color of the cta button
export const FIRSTLAUNCH_UPSELL_CTA_COLOR =
  'web.premium.firstLaunchUpsell.cta.color';

// experiment to change the label text of the cta button
export const FIRSTLAUNCH_UPSELL_CTA_TEXT =
  'web.premium.firstLaunchUpsell.cta.text';

// experiment to change the headline text
export const FIRSTLAUNCH_UPSELL_H1_TEXT =
  'web.premium.firstLaunchUpsell.headline.text';

// experiment to enable scheduled upsell dialog on subsequent visits
export const SUBSEQUENT_LAUNCH_UPSELL_DIALOG_ENABLED =
  'web.premium.subsequentLaunchUpsell.enabled';
export const SUBSEQUENT_LAUNCH_UPSELL_DIALOG_X_DAYS =
  'web.premium.subsequentLaunchUpsell.showXDaysAfterSeen';

// *** GENERAL UPSELL EXPERIMENT KEYS *** //
// Specifies if commercial free music is enabled.
export const COMMERCIAL_FREE_MUSIC_ENABLED = 'commercialFreeMusic.enabled';

// experiment for upsell CTA copy
export const UPSELL_CTA_TEXT = 'web.premium.upsell.cta.text';

// upsell banner experiment keys
export const UPSELL_BANNER_BUTTON_BG_COLOR = 'web.whyadsv2.buttoncolor';
export const UPSELL_BANNER_BUTTON_TEXT_COLOR = 'web.whyadsv2.buttontextcolor';

// experiment for right sidebar upsell design
export const NEW_RIGHTSIDEBAR_UPSELL_ENABLED =
  'web.premium.newRightSidebarUpsell.enabled';

// experiment for Landing premium table CTA
export const LANDING_PREMIUM_OFFERING_TABLE_PRICE_TEXT =
  'web.landing.premium.offering.table.price.text';
