import isFunction from 'lodash/isFunction';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectIsDiscord } from '../../../selectors/app';
import { discordFriendlyExternalLinking } from '../../../utils/discord';

/*
  Component is to be used for links that should open in a new window
  This provides the desktop handler to open in browser
*/

const ExternalLink = ({
  children,
  dataTestId,
  href,
  onClick,
  ...otherProps
}) => {
  const isDiscord = useSelector(selectIsDiscord);
  const onClickCallback = (ev) => {
    if (isDiscord) {
      ev?.preventDefault();
      discordFriendlyExternalLinking(href);
    }

    if (isFunction(onClick)) {
      onClick();
    }
  };

  return (
    <a
      data-testid={dataTestId}
      target="_blank"
      rel="noopener"
      href={href}
      onClick={onClickCallback}
      {...otherProps}
    >
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
};

export default ExternalLink;
