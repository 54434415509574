import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import assetUrl from 'src/common/utils/assetUrl';
import ExternalLink from './ExternalLink';
import css from './chevron-link.module.scss';

const chevronRightAssetLoc = 'assets/img/shared/chevron-right-bold.svg';
/*
  Component is to be used for links that represent sections of content, where the link itself
  requires a right chevron hover animation.
*/
const ChevronRightLink = ({
  to,
  assetLoc,
  isNotReactRouterLink,
  children,
  className,
  chevronClassName,
  injectSvg,
  ...otherProps
}) => {
  const baseProps = {
    className: classNames(css.link, className),
    ...otherProps,
  };

  const chevronElement = injectSvg ? (
    <ReactSVG
      beforeInjection={(svg) => {
        // note: IE11 does not support Element.classlist for SVG elements
        svg.setAttribute(
          'class',
          classNames(css.chevronRight, chevronClassName),
        );
      }}
      className={css.chevronWrapper}
      src={assetUrl(assetLoc || chevronRightAssetLoc)}
    />
  ) : (
    <i
      className={classNames(
        css.chevronRight,
        css.chevronRightBoldBackground,
        chevronClassName,
      )}
    />
  );

  return isNotReactRouterLink ? (
    <ExternalLink {...baseProps} href={to}>
      {children}
      {chevronElement}
    </ExternalLink>
  ) : (
    <Link {...baseProps} to={to}>
      {children}
      {chevronElement}
    </Link>
  );
};

ChevronRightLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isNotReactRouterLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  assetLoc: PropTypes.string,
  className: PropTypes.string,
  chevronClassName: PropTypes.string.isRequired,
  injectSvg: PropTypes.bool,
};

export default ChevronRightLink;
