import { hostModes } from '../../../discord';

// MAKE HOST
export const DISCORD_IS_HOST_USER_LIST_LABEL = 'discord.isHost.userList.label';

// CURRENT HOST
export const DISCORD_IS_NOT_HOST_USER_LIST_LABEL =
  'discord.isNotHost.userList.label';

// {{bold}}You{{/bold}} are hosting
const DISCORD_HOSTED_MODE_IS_HOST_BUTTON_LABEL =
  'discord.hostedMode.isHost.button.label';

// {{bold}}{host}{{/bold}} is hosting
const DISCORD_HOSTED_MODE_IS_NOT_HOST_BUTTON_LABEL =
  'discord.hostedMode.isNotHost.button.label';

// {{bold}}You{{/bold}} are sharing
const DISCORD_SHARED_MODE_IS_HOST_BUTTON_LABEL =
  'discord.sharedMode.isHost.button.label';

// {{bold}}{host}{{/bold}} is sharing
const DISCORD_SHARED_MODE_IS_NOT_HOST_BUTTON_LABEL =
  'discord.sharedMode.isNotHost.button.label';

// Allow everyone to choose stations and playback
const DISCORD_MODE_TOGGLE_LABEL = 'discord.mode.toggle.label';

// Only the host can choose stations and control playback
const DISCORD_HOSTED_MODE_IS_NOT_HOST_INFO_LABEL =
  'discord.hostedMode.isNotHost.info.label';

// Everyone can choose stations and control playback
const DISCORD_SHARED_MODE_IS_NOT_HOST_INFO_LABEL =
  'discord.sharedMode.isNotHost.info.label';

// Discord user avatar
export const DISCORD_USER_AVATAR_IMAGE_LABEL =
  'discord.user.avatar.image.label';

// Text displayed to non-host upon play button hover
export const DISCORD_PLAYBACK_DISABLED_TEXT = 'discord.playback.disabled.text';

const HOST_KEY = true;
const NOT_HOST_KEY = false;

export const localizationKeyMap = {
  [hostModes.hosted]: {
    [HOST_KEY]: {
      infoLabelKey: DISCORD_MODE_TOGGLE_LABEL,
      buttonLabelKey: DISCORD_HOSTED_MODE_IS_HOST_BUTTON_LABEL,
    },
    [NOT_HOST_KEY]: {
      infoLabelKey: DISCORD_HOSTED_MODE_IS_NOT_HOST_INFO_LABEL,
      buttonLabelKey: DISCORD_HOSTED_MODE_IS_NOT_HOST_BUTTON_LABEL,
    },
  },
  [hostModes.shared]: {
    [HOST_KEY]: {
      infoLabelKey: DISCORD_MODE_TOGGLE_LABEL,
      buttonLabelKey: DISCORD_SHARED_MODE_IS_HOST_BUTTON_LABEL,
    },
    [NOT_HOST_KEY]: {
      infoLabelKey: DISCORD_SHARED_MODE_IS_NOT_HOST_INFO_LABEL,
      buttonLabelKey: DISCORD_SHARED_MODE_IS_NOT_HOST_BUTTON_LABEL,
    },
  },
};

export const DISABLED_BUTTON_TIMEOUT = 5000;
