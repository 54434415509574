import classNames from 'clsx';
import interpolateComponents from 'interpolate-components';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectExperiment,
  selectIsSubscriptionEnabled,
} from 'src/common/selectors/config';
import {
  selectIsExpiredWebSubscriber,
  selectIsUserSubscribed,
} from 'src/common/selectors/me';
import { logSubscribeActivity } from '../../actions/logging';
import subscribe, {
  labels,
} from '../../constants/analytics/categoryActionLabel/subscribe';
import {
  RIGHTUPSELL_TITLE_DISCOUNT,
  UPSELL_COMMERCIAL_FREE_MUSIC,
  UPSELL_COMMERCIAL_FREE_NEWS,
  UPSELL_COMMERCIAL_FREE_SPORTS,
  UPSELL_GO_PREMIUM,
  UPSELL_LESS_ADS,
  UPSELL_SIDEBAR_HEADLINE,
  UPSELL_START_YOUR_FREE_TRIAL,
  UPSELL_UPGRADE_NOW,
} from '../../constants/localizations/upsell';
import useActions from '../../hooks/useActions';
import cssVariables from '../../styles/variables';
import createSubscribeLink from '../../utils/subscription/createSubscribeLink';
import getSubscribeEventLabel from '../../utils/subscription/getSubscribeEventLabel';
import PillButton from '../shared/button/PillButton';
import Checkmark from '../shared/svgIcons/LegacyCheckmark';

import {
  LINE_BREAK_INSTRUCTION,
  LINE_BREAK_INTERPOLATE,
} from '../../constants/localizations/shared';

import {
  COMMERCIAL_FREE_MUSIC_ENABLED,
  NEW_RIGHTSIDEBAR_UPSELL_ENABLED,
} from '../../constants/experiments/upsell';
import useOnMount from '../../hooks/useOnMount';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import css from './upsell.module.scss';

const svgProps = {
  width: '14px',
  height: '12px',
  viewBox: '0 0 14 12',
  fill: cssVariables['--primary-color-1'],
  className: css.checkmark,
};

const dataTestIdPrefix = 'sidebarUpsell';

function UpsellRightSidebar({ guideId, isProfile }) {
  const actions = useActions({ logSubscribeActivity });
  const isUserSubscribed = useSelector(selectIsUserSubscribed);
  const isSubscriptionEnabled = useSelector(selectIsSubscriptionEnabled);
  const isExpiredWebSubscriber = useSelector(selectIsExpiredWebSubscriber);
  const isCommercialFreeMusicEnabled = useSelector((state) =>
    selectExperiment(state, COMMERCIAL_FREE_MUSIC_ENABLED),
  );
  const newRightSidebarUpsellEnabled = useSelector((state) =>
    selectExperiment(state, NEW_RIGHTSIDEBAR_UPSELL_ENABLED),
  );
  const { getLocalizedText, location } = useContext(
    LocationAndLocalizationContext,
  );
  const shouldRender = !isUserSubscribed && isSubscriptionEnabled;
  const eventLabel = getSubscribeEventLabel(labels.sidebarUpsell, location);

  useOnMount(() => {
    if (shouldRender) {
      actions.logSubscribeActivity(subscribe.actions.show, eventLabel, {
        guideId,
      });
    }
  });

  if (!shouldRender) {
    return null;
  }

  let titleKey = isExpiredWebSubscriber
    ? UPSELL_UPGRADE_NOW
    : UPSELL_SIDEBAR_HEADLINE;
  const buttonKey = isExpiredWebSubscriber
    ? UPSELL_GO_PREMIUM
    : UPSELL_START_YOUR_FREE_TRIAL;

  if (newRightSidebarUpsellEnabled) {
    svgProps.fill = cssVariables['--premium-gold'];
    titleKey = RIGHTUPSELL_TITLE_DISCOUNT;
  }

  const title = getLocalizedText(titleKey).replace(
    LINE_BREAK_INSTRUCTION,
    LINE_BREAK_INTERPOLATE,
  );

  return (
    <div
      className={classNames(css.sidebarWrapper, {
        [css.isProfile]: isProfile,
        [css.newDesign]: newRightSidebarUpsellEnabled,
      })}
      id={dataTestIdPrefix}
      data-testid={dataTestIdPrefix}
    >
      {newRightSidebarUpsellEnabled && (
        <div className={css.newUpsellLogosContainer} />
      )}
      <h1
        id={`${dataTestIdPrefix}Headline`}
        data-testid={`${dataTestIdPrefix}Headline`}
        className={classNames(css.title, {
          [css.newDesign]: newRightSidebarUpsellEnabled,
        })}
      >
        {interpolateComponents({
          mixedString: title,
          components: {
            linebreak: <br />,
          },
        })}
      </h1>
      {!newRightSidebarUpsellEnabled && (
        <table
          className={classNames(css.list, {
            [css.newDesign]: newRightSidebarUpsellEnabled,
          })}
          id={`${dataTestIdPrefix}FeatureList`}
          data-testid={`${dataTestIdPrefix}FeatureList`}
        >
          <tbody>
            <tr
              id={`${dataTestIdPrefix}FeatureListCommercialFreeSports`}
              data-testid={`${dataTestIdPrefix}FeatureListCommercialFreeSports`}
            >
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td>{getLocalizedText(UPSELL_COMMERCIAL_FREE_SPORTS)}</td>
            </tr>
            <tr
              id={`${dataTestIdPrefix}FeatureListCommercialFreeNews`}
              data-testid={`${dataTestIdPrefix}FeatureListCommercialFreeNews`}
            >
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td>{getLocalizedText(UPSELL_COMMERCIAL_FREE_NEWS)}</td>
            </tr>
            {isCommercialFreeMusicEnabled && (
              <tr
                id={`${dataTestIdPrefix}FeatureListCommercialFreeMusic`}
                data-testid={`${dataTestIdPrefix}FeatureListCommercialFreeMusic`}
              >
                <td>
                  <Checkmark {...svgProps} />
                </td>
                <td>{getLocalizedText(UPSELL_COMMERCIAL_FREE_MUSIC)}</td>
              </tr>
            )}
            <tr
              id={`${dataTestIdPrefix}FeatureListNoDisplayAds`}
              data-testid={`${dataTestIdPrefix}FeatureListNoDisplayAds`}
            >
              <td>
                <Checkmark {...svgProps} />
              </td>
              <td>{getLocalizedText(UPSELL_LESS_ADS)}</td>
            </tr>
          </tbody>
        </table>
      )}
      <Link
        className={classNames(css.buttonLinkWrapper, {
          [css.newDesign]: newRightSidebarUpsellEnabled,
        })}
        data-testid="sidebarStartTrialLink"
        to={createSubscribeLink({
          source: eventLabel,
          guideId,
          location,
        })}
      >
        <PillButton
          className={classNames(css.button, {
            [css.newDesign]: newRightSidebarUpsellEnabled,
          })}
          id={`${dataTestIdPrefix}StartTrialButton`}
          dataTestId={`${dataTestIdPrefix}StartTrialButton`}
          onClick={() =>
            actions.logSubscribeActivity(subscribe.actions.tap, eventLabel, {
              guideId,
            })
          }
          label={getLocalizedText(buttonKey)}
        />
      </Link>
    </div>
  );
}

UpsellRightSidebar.propTypes = {
  guideId: PropTypes.string.isRequired,
  isProfile: PropTypes.bool,
};

export default UpsellRightSidebar;
