import { Component } from 'react';

/*
 * Use this class to ensure DisplayAdSlot objects only mount client side, and after
 * breakpoints have been appropriately pulled in from the browser.  Without this wrapper
 * it's possible mutliple display ads with the same ad unit id will render briefly before
 * breakpoints are registered, which can cause problems serving the ad (and is also
 * conceptually incorrect).
 */
export default function withDisplayAdWrapper(WrappedComponent) {
  return class WithDisplayAdWrapper extends Component {
    state = {
      adsReady: false,
    };

    componentDidMount() {
      // this eslint rule is in place to disable component tearing/re-rendering
      // in this case, no tearing can occur, not a valid rule
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        adsReady: true,
      });
    }

    render() {
      const { adsReady } = this.state;

      return adsReady ? <WrappedComponent {...this.props} /> : null;
    }
  };
}
