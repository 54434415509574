import PropTypes from 'prop-types';
import { getCssStyle } from '../../../utils/getCssStyle';
/* eslint-disable max-len */

export const InfoIcon = ({
  height = '14px',
  width = '14px',
  color = getCssStyle('--text-color'),
  opacity,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15Z"
      stroke={color}
      strokeOpacity={opacity}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M9 6.2998L9 6.8998"
      stroke={color}
      strokeOpacity={opacity}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
    <path
      d="M9 8.7002L9 11.7002"
      stroke={color}
      strokeOpacity={opacity}
      strokeMiterlimit="10"
      strokeLinecap="square"
    />
  </svg>
);

InfoIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  opacity: PropTypes.string,
};
