import classNames from 'clsx';
import PropTypes from 'prop-types';
import CloseButton from './button/CloseButton';
import css from './popover.module.scss';

export default function Popover({
  isOpen,
  className,
  children,
  contentClassName,
  showCornerClose,
  handleCloseClick,
  styleOverride,
  popoverRef,
}) {
  return (
    <div
      data-testid="popover-container"
      className={classNames(css.container, className, {
        [css.isClosed]: !isOpen,
      })}
      style={styleOverride}
      ref={popoverRef}
    >
      <div
        data-testid="popover-content"
        className={classNames(css.content, contentClassName)}
      >
        {showCornerClose && (
          <CloseButton
            handleDialogClose={handleCloseClick}
            className={css.closeIcon}
          />
        )}
        {children}
      </div>
    </div>
  );
}

Popover.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  handleCloseClick: PropTypes.func,
  popoverRef: PropTypes.object,
  styleOverride: PropTypes.object,
};
